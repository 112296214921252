<style scoped>
.main {
    min-width: 1200px;
    padding: 10px 100px;
}
.column {
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #eaeaea;
    margin-bottom: 10px;
    padding: 12px;
}
.course_img {
    float: left;
    height: 150px;
    margin-right: 12px;
}
.course_img:hover {
    cursor: pointer;
}
.title {
    /* font-weight: bold;
			font-size: larger;
			margin-right: 4px; */
    font-size: x-large;
    margin-right: 6px;
}
.tag {
    margin-right: 4px;
    background: #edeff2;
    border-radius: 4px;
    font-size: x-small;
    color: #667280;
    padding: 2px 6px;
}
.external_link {
    padding-top: 12px;
}
.external_link span {
    font-size: small;
}
.base_button {
    vertical-align: middle;
    background-color: #ffffff;
    border: 1px solid #d9ecff;
    border-radius: 4px;
    margin-right: 6px;
}
.base_button:hover {
    cursor: pointer;
    border: 1px solid #ff0000;
}
.img_button {
    padding-top: 4px;
}
.text_button {
    font-weight: bold;
    padding: 8px 12px;
}
.text_button_small {
    padding: 6px 10px;
    font-size: small;
}
.small_logo {
    height: 22px;
}
</style>

<template>
    <div>
        <Header />
        <div class="main">
            <div class="column">
                <img
                    src="/pictures/course/mysql_course_cover_02.gif"
                    class="course_img"
                    @click="openNewWindow('https://edu.51cto.com/course/32462.html')"
                />
                <a class="title" href="https://edu.51cto.com/course/32462.html" target="_blank">MySQL从入门到精通</a>
                <span class="tag">MySQL</span>
                <span class="tag">从入门到精通</span>
                <div class="external_link">
                    <span style="font-size: small; color: #999">本课程深入讲解MySQL高级用法，进一步了解MySQL。</span>
                </div>
                <div class="external_link">
                    <span>发布平台：</span>
                    <button class="base_button img_button" @click="openNewWindow('https://edu.51cto.com/course/32462.html')">
                        <img class="small_logo" src="/pictures/platform/edu.51cto.logo.png" />
                    </button>
                </div>
                <div class="external_link">
                    <span>资料下载：</span>
                    <button class="base_button img_button" @click="openNewWindow('https://pan.baidu.com/s/1c1KBRcSDikUjzr2Z8mOXMg?pwd=h35r')">
                        <img class="small_logo" src="/pictures/platform/pan.baidu.logo.gif" />
                    </button>
                    <span style="margin-left: 6px">提取码：h35r</span>
                </div>
                <div class="external_link">
                    <span>推广：</span>
                    <button
                        class="base_button text_button_small"
                        @click="
                            openNewWindow(
                                'https://cloud.tencent.com/act/cps/redirect?redirect=2446&cps_key=00ed61e92748fbbbf799486a6bc05854&from=console'
                            )
                        "
                    >
                        <span style="color: #006eff">腾讯云特惠</span>
                    </button>
                    <button
                        class="base_button text_button_small"
                        @click="openNewWindow('https://www.aliyun.com/daily-act/ecs/activity_selection?userCode=zahxwqv2')"
                    >
                        <span style="color: #ff6a00">阿里云特惠</span>
                    </button>
                </div>
            </div>
            <div class="column">
                <img
                    src="/pictures/course/mysql_course_cover_01.gif"
                    class="course_img"
                    @click="openNewWindow('https://edu.51cto.com/course/31689.html')"
                />
                <a class="title" href="https://edu.51cto.com/course/31689.html" target="_blank">MySQL零基础入门视频教程</a>
                <span class="tag">MySQL</span>
                <span class="tag">零基础入门</span>
                <div class="external_link">
                    <span style="font-size: small; color: #999">本课程章节分明，先易后难，循序渐进，小白也适合学习本课程入门MySQL。</span>
                </div>
                <div class="external_link">
                    <span>发布平台：</span>
                    <button class="base_button img_button" @click="openNewWindow('https://edu.51cto.com/course/31689.html')">
                        <img class="small_logo" src="/pictures/platform/edu.51cto.logo.png" />
                    </button>
                    <!-- <button class="base_button img_button" @click="openNewWindow('https://ke.qq.com/course/5641805?_wv=2147487745')">
					<img class="small_logo" src="/pictures/platform/ke.qq.logo.png" />
				</button> -->
                </div>
                <div class="external_link">
                    <span>资料下载：</span>
                    <button class="base_button img_button" @click="openNewWindow('https://pan.baidu.com/s/1YAap1lILIK3MBg1hERE2nQ?pwd=vxvp')">
                        <img class="small_logo" src="/pictures/platform/pan.baidu.logo.gif" />
                    </button>
                    <span style="margin-left: 6px">提取码：vxvp</span>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Header from "../common/Header.vue";
import Footer from "../common/Footer.vue";

export default {
    //注册组件
    components: {
        Header,
        Footer
    },
    data() {
        return {};
    },
    methods: {
        openNewWindow(url) {
            window.open(url);
        }
    },
    mounted() {}
};
</script>
